<script>
export default {
  data() {
  },
  components: {
  },
  methods: {
  }
};
</script>

<template>
  <h4>Політика конфіденційності сервісу «BUSYCAST»</h4>
  <p>Дана Політика є невід’ємною частиною Умов та правила адресованих фізичною особою-підприємцем Кошовим Дмитром Вячеславовичем, який діє на підставі Свідоцтва, особам (далі — «Користувач», «Автор», «Підписник»), приймаючи умови якої (здійснюючи акцепт оферти), у відповідності до Закону України «Про захист персональних даних», Ви надаєте письмову згоду на обробку персональних даних, в обсязі та на умовах, зазначених в Політиці конфіденційності, викладеній нижче.</p>
  <p>Використовуючи сервіс «BUSYCAST», Ви довіряєте нам свою особисту інформацію. Ми робимо все для забезпечення її безпеки.</p>
  <p>Персональні дані використовуються з метою ідентифікації для створення акаунту, перевірки аканту, запобігання шахрайських дій, що порушують чинне законодавство України, моніторинг вашої активності в онлайн трансляціях, для поліпшення Сервісу та створення нових послуг та інструменті (віджетів), для отримання грошових зарахувань, відображення статистики у віджетах, котрі ви використаєте з власної волі.</p>
  <p>Розміщуючи у Сервісі свої персональні дані, заповнюючи будь-які форми, Користувач підтверджує, що робить це добровільно та у повній відповідності з вимогами чинного законодавства України, а у випадках, визначених Умовами, - згідно з вимогами національного законодавства держави юрисдикції Користувача.</p>
  <p>Платіжні реквізити  при оплаті замовлення банківською картою, обробка платежу (включаючи введення номера картки) відбувається на захищеній сторінці процессинговой системи Банку. Це означає, що конфіденційні дані (реквізити карти, реєстраційні дані та ін.) не надходять на сайт Сервісу, їх обробка повністю захищена і ніхто не може отримати персональні та банківські дані Користувача. При роботі з картковими даними застосовується стандарт захисту інформації, розроблений міжнародними платіжними системами Visa та MasterCard - Payment Card Industry Data Security Standard (PCI DSS), що забезпечує безпечну обробку реквізитів банківської карти Підписника. Застосовувана технологія передачі даних гарантує безпеку по операціях з банківськими картами шляхом використання протоколів Secure Sockets Layer (SSL), Verified by Visa, SecureCode, і закритих банківських мереж, що мають вищий ступінь захисту.</p>
  <p>Персональні дані, які ми використовуємо:</p>
  <ul>
    <li>ідентифікатор (ID) Вашого профілю Google;</li>
    <li>адреса електронної пошти (e-mail);</li>
    <li>фото Вашого профілю Google;</li>
    <li>держава, у якій Ви перебуваєте;</li>
    <li>дані неохідні для ідентифікації при створенні акаунту;</li>
  </ul>
  <p>Ми зберігаємо зібрані дані протягом деякого часу. Тривалість зберігання залежить від типу інформації, а також від того, як ми її використовуємо та які налаштування Ви задали.</p>
  <p>Ви можете у будь-який момент самостійно видалити деякі дані, у тому числі особисту інформацію або створений або завантажений Вами Контент.</p>
  <p>Ми можемо надати Вашу особисту інформацію юридичним та фізичним особам, які не пов'язані з Компанією, виключно на підставах передбачених чинним законодавством України.</p>
  <p>Ми періодично вносимо до Політики конфіденційності зміни, однак не маємо наміру в майбутньому обмежувати права користувачів, описані в цій Політиці, без їхньої явної згоди. Ми завжди вказуємо дату останніх змін, внесених до Політики, та надаємо доступ до її попередніх версій. Якщо зроблено значні поправки, ми оголошуємо про них додатково, а в деяких випадках можемо надсилати повідомлення електронною поштою.</p>
</template>